<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="배출구별 주요배출시설 가동(조업)시간"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :columnSetting="false"
        :gridHeightAuto="param.isFullScreen"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData"/>
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-01',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envAirDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'dischargeName',
            field: 'dischargeName',
            label: '배출시설',
            align: 'center',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'operationHour',
            field: 'operationHour',
            label: '가동시간(시)',
            align: 'center',
            type: 'number',
            style: 'width:140px',
            sortable: false,
            setHeader: true,
          },
          {
            name: 'operationMin',
            field: 'operationMin',
            label: '가동시간(분)',
            align: 'center',
            type: 'number',
            style: 'width:140px',
            sortable: false,
            setHeader: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        height: '600px',
        data: [],
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.daily.outlet.list.url;
      this.saveUrl = transactionConfig.env.air.daily.outlet.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirDailyResultId: this.param.envAirDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', /* 안내 */
          message: '변경된 데이터가 없습니다.', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
  }
};
</script>
